import './App.css';
import Header from './components/Header';
import Top from './components/Top';
import Philosophies from './components/Philosophies';
import TrialButton from './parts/TrialButton';
import Players from './components/Players';
import FurtherEduWrapper from './components/FutherEduWrapper';
import Trial from './components/Trial';
import Review from './components/Review';
import Contact from './components/Contact';
import Footer from './components/Footer';
function App() {
  return (
    <div className=" bg-stone-900 text-white font-hiragino text-center">
      <Header />
      <Top />
      <Philosophies />
      <TrialButton/>
      <Players />
      <FurtherEduWrapper />
      <Trial />
      <Review />
      <Contact />
      <Footer />

    </div>
  );
}

export default App;
