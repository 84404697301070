function CirclePannnel (props) {
    return (
        <div className="bg-gray-600 w-80 h-80 rounded-full m-auto flex justify-center items-center my-6">
            <div className="align-middle">
               <h3 className="text-2xl font-bold">{props.title}</h3>
               <p className="py-3 w-11/12 m-auto text-xl leading-loose">{props.text}</p>
            </div>
        </div>
    );
}

export default CirclePannnel;