import hero from "../assets/hero.jpg";

function Top () {
    return (
        <div>
        <img className='w-4/5 md:w-1/2 m-auto rounded' src={hero} alt="hero" />
        <h1 className="text-center text-2xl font-bold py-10 ">ZIPS BASKETBALL ACADEMY</h1>
        <p className="w-11/12 md:w-1/2 m-auto leading-loose py-4 text-left">ZIPS BASKETBALL ACADEMY(ジップス バスケットボール アカデミー)は2020年から群馬県東毛地区をメインに活動しているバスケットボールチームです。当アカデミーは日本一を目指して日々練習を行っております。</p>
        <p className="text-center text-red text-2xl py-5">U15クラス創立3年で初の全国大会出場</p>

        </div>
    );
}

export default Top;