import CircleWrapper from "../components/CircleWrapper";

function Philosophies () {
    return (
        <div>
            <h2 className="">理念</h2>
            <CircleWrapper />
        </div>
    );
}

export default Philosophies;