import players_img from"../assets/players_img.jpg"
function Players () {
    return (
        <div>
            <img className="w-4/5 rounded md:w-1/2 m-auto" src={players_img} alt="" />

            <h2>選手数</h2>

            <div className="w-4/5 mx-auto my-10">
               <h3 className="font-bold text-xl">U15(中学生年代)約20名</h3>
               <small>館林市/邑楽郡/大泉町/太田市/桐生市/栃木県足利市/埼玉県行田市/埼玉県熊谷市</small>
            </div>

            <div className="w-4/5 mx-auto my-10">
               <h3 className="font-bold text-xl">U12(小学生年代)約20名</h3>
               <small>館林市/邑楽郡/大泉町/太田市/桐生市/前橋市/栃木県足利市</small>
            </div>


            
        </div>
    );
}

export default Players;