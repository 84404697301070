
function Career () {
    return (
        <div className="w-11/12 md:w-1/2 m-auto flex flex-wrap 	flex-direction: row; ">            
            <img className="w-1/2 md:w-1/3 m-auto" src="background/yamagata.png" alt="山形県 羽黒高等学校" />
            <img className="w-1/2 md:w-1/3 m-auto" src="background/gunma.png" alt="群馬県 前橋商業高等学校 館林高等学校" />
            <img className="w-1/2 md:w-1/3 m-auto" src="background/tochigi.png" alt="栃木県 宇都宮工業高等学校" />
            <img className="w-1/2 md:w-1/3 m-auto" src="background/niigata.png" alt="新潟県 帝京長岡高校" />
            <img className="w-1/2 md:w-1/3 m-auto" src="background/fukui.png" alt="福井県 北陸高等学校" />
            <img className="w-1/2 md:w-1/3 m-auto" src="background/kouchi.png" alt="高知県 明徳義塾高等学校" />

            
        </div>
    );
}

export default Career;