import trial from "../assets/trial.jpg"
import TrialButton from '../parts/TrialButton';
import { FaSquareFull } from "react-icons/fa";


function Trial() {
    return (
        <div>
            <img className="w-4/5 md:w-1/2 m-auto rounded" src={trial} alt="" />

            <div className="w-4/5 md:w-1/2 m-auto py-6 font-bold text-l text-center leading-loose">
                <p className="">バスケ未経験でも大丈夫。<br />チームでの練習に参加してみませんか?<br />バスケットに興味がある子、<br />バスケットを継続したい選手...<br />たくさんのお子様の<br />ご参加をお待ちしております。<br /> まずはお気軽にお申し付けください♪</p>
            </div>

            <div className="w-4/5 md:w-2/5 m-auto text-left font-bold">
                <ul>
                <h3><FaSquareFull className="inline text-xs items-end mr-1" />対象</h3>
                <li>年長〜中学3年生 男女問わず</li>

                </ul>

                <ul>
                <h3><FaSquareFull className="inline text-xs items-end mr-1" />参加費</h3>
                <li>無料</li>
                </ul>

                <ul>
                <h3><FaSquareFull className="inline text-xs items-end mr-1" />開催場所</h3>

                <ul>
                <li className="underline">年長〜小学生の部</li>
                <li>月 水 : 太田市内の体育館</li>
                <li>金 土 日 :  邑楽郡の体育館</li>

                </ul>
                <ul>
                <p className="underline">中学生の部</p>
                <p>月 火 水 : 太田市内の体育館</p>
                <p>金 土 日 :  邑楽郡の体育館</p>
                </ul>
                </ul>
                


                <small>※場所の詳細は個別にご連絡させて頂きます。</small>

                <ul>
                <h3><FaSquareFull className="inline text-xs items-end mr-1" />開催時間</h3>
                <ul>
                <p className="underline">年長〜小学生の部</p>
                <li>平日 : 18:30~21:00</li>
                <li>休日 : 13:00~14:30</li>

                </ul>
                <ul>
                <p className="underline">中学生の部</p>
                <li>平日 : 18:30~21:00</li>
                <li>休日 : 13:00~14:30</li>
                </ul>


                </ul>

                <ul>
                <h3><FaSquareFull className="inline text-xs items-end mr-1" />持ち物</h3>
                <li>運動靴 運動着 タオル 飲み物</li>
                </ul>

                <ul>
                <h3><FaSquareFull className="inline text-xs items-end mr-1" />注意事項</h3>
                <li>体験練習はスポーツ安全保険に未加入となるため、練習及び往復中のケガ、事故については、保護者の監督・責任のもとでお願いいたします。</li>
                </ul>




            </div>
            <TrialButton />
            <small className="block -mt-8">※1ヶ月間の無料体験練習が、可能です。</small>

            <div className="w-4/5 md:w-1/3 m-auto my-5 text-white text-left">
            <p>お問い合わせフォームにて、下記の項目をご記入の上、ご送付ください。</p>
            <ul>
                <li>①学校名</li>
                <li>②学年</li>
                <li>③お名前</li>
                <li>④所属チーム</li>
            </ul>

            </div>

            
        </div>
    ) ;

    
}

export default Trial;