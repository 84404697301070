import TrialButton from "../parts/TrialButton";
import ContactButton from "../parts/ContactButton";
import { FaInstagram } from "react-icons/fa";
import { BsTelephone } from "react-icons/bs";
import { CiMail } from "react-icons/ci";
function Contact () {
    return (
        <div>
            <h2>お問い合わせ</h2>
            <TrialButton />
            

        <ul className="w-3/5 md:w-1/3 md:hidden m-auto スマホ flex flex-wrap justify-between">
            

            <li className="my-6">
            <div className="flex flex-wrap">
                    
                    <a className="block" href="mailto:zips.basketball.academy@gmail.com"><CiMail className="text-6xl mr-3" /></a>
                </div>
            </li>

            <li className="my-6">
             <div className="flex flex-wrap">
                <a className="block" href="tel:0276-61-3442"><BsTelephone className="text-6xl mr-3 m-auto" /></a>
            </div>
            <p className="mt-2 text-center">担当:宮下</p>

            </li>



            <li className="my-6">
            <div className="flex flex-wrap">
                <a className="block" href="https://www.instagram.com/zips_basketball_academy/"><FaInstagram className="text-6xl mr-3" /></a>
                </div>
                
            </li>


         </ul>

         <ul className="w-4/5 md:w-1/3 md:block hidden m-auto ">
            
            <li className="my-6">
             <div className="flex flex-wrap">
                <BsTelephone className="text-3xl mr-3" />
                <a className="block" href="tel:0276-61-3442">0276-61-3442 (事務局担当 :宮下)</a>
            </div>
            </li>

            <li className="my-6">
            <div className="flex flex-wrap">
                    <CiMail className="text-3xl mr-3" />
                    <a className="block" href="mailto:zips.basketball.academy@gmail.com">zips.basketball.academy@gmail.com</a>
                </div>
            </li>


            <li className="my-6">
            <div className="flex flex-wrap">
                <FaInstagram className="text-3xl mr-3" />
                <a className="block" href="https://www.instagram.com/zips_basketball_academy/">@zips_basketball_academy</a>
                </div>
                
            </li>


         </ul>






        </div> 
    );
}

export default Contact;