import boy from '../assets/boy.png'
import lady from '../assets/lady.png'

function Review () {
    return (
        <div>
            <h2>口コミ</h2>
         <div className='text-left w-11/12 md:w-2/5 m-auto flex py-5 my-10'>
    
          <div className='w-2/3 md:w-1/5'>
            <div className='w-2/3 m-auto'>
                <img className='' src={lady} alt="" />
            </div>

            <div className='text-center py-2'>
                <p>保護者</p>
            </div>


                
            </div>



            <div className='w-11/12 md:w-4/5 my-auto align-middle text-xs md:text-sm leading-5'>
                <p>チーム全体が仲が良く、出来ないことも親切に教えてくれていました。何回か参加するにつれ楽しめるようになってきたと思います。緊張している娘に親切にしてくれている様子をみてこのチームならバスケを楽しみながらプレーできそうだなと思いました。基礎練習から始まり、体力づくり、5対5、ゲームと考えられた練習だと感じました。</p>
            </div>

        </div>



        <div className='text-left w-11/12 md:w-2/5 m-auto flex py-5 my-10'>
    
    <div className='w-2/3 md:w-1/5'>
      <div className='w-2/3 m-auto'>
          <img className='' src={boy} alt="" />
      </div>

      <div className='text-center py-2'>
          <p>男の子(12歳)</p>
      </div>


          
      </div>



      <div className='w-11/12 md:w-4/5 my-auto align-middle text-xs md:text-sm leading-5'>
          <p>みんな上手だから自分ももっと上手になりたいと思った。緊張したけど、練習の内容を中学生が優しく教えてくれて緊張も解けて楽しく練習を行うことができた。積極的に中学生が声を掛けてくれて嬉しかった。中学生が優しく接してくれて楽しく練習することができた。</p>
      </div>

  </div>


                
        </div>
    );
}

export default Review;